/*-------------------
    Column Area  
---------------------*/

.grounds {
    &:hover{

        transform: translateY(-4px);

            
    }
}

.single-column {

    h4 {
        &.tilte {
            font-size: 2em;
            font-family: raleway, sans-serif;
            margin-bottom: 10px;
        }
    }
    p {
        font-family: raleway, sans-serif;
        font-size: 16px;
        line-height: 28px;
    }
    &.custom-color {
        background: $theme-color;
        padding: 37px 29px;
        h4 {
            &.tilte {
                color: #ffffff;
            }
        }
        p {
            color: #ffffff;
        }
        &.custom-color--1 {
            background: #001c43;
        }
        &.custom-color--2 {
            background: #c93ec7;
        }
    }
}

