/*------------------------
    Team Styles
----------------------*/
h6 {
    font-size: 1.3em;
    font-family: raleway, sans-serif;
    padding-top: 30px;
    text-align: center;
}

.contentBelow{
        font-family: raleway, sans-serif;
        padding-top: 2%;
 
        @media #{$large-mobile} {
            display: initial;

        }

        p {

            &.designation2 {
                font-weight: 700;

                
            }
            }

    }


.team {
    font-family: raleway, sans-serif;
    margin-top: 30px;
    overflow: hidden;
    border-radius: 6px;
    position: relative;
    .thumbnail {
        display: block;
        position: relative;
        z-index: 1;
        img {
            width: 100%;
            @extend %transition;
        }
        &::after {
            background: linear-gradient(to bottom, #661B0B 0, #000000 100%);
            position: absolute;
            left: 0;
            right: 0;
            width: 100%;
            height: 100%;
            content: "";
            z-index: 1;
            opacity: 0;
            @extend %transition;

        }
    }
    .content {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 35px 40px;
        z-index: 2;
        h4 {
            &.title {
                color: #ffffff;
                margin: 0;
                opacity: 0;
                visibility: hidden;
                transition-delay: 0.25s;
                transform: translateY(10px);
                transition: all 0.45s cubic-bezier(0.23, 0.88, 0.34, 0.99);

                a {
                    color: #ffffff;
                }
            }
        }
        p {
            &.designation {
                color: #c6c9d8;
                font-size: 15px;
                line-height: 25px;
                opacity: 0;
                visibility: hidden;
                transition: all 0.45s cubic-bezier(0.23, 0.88, 0.34, 0.99);
                transform: translateY(10px);

            }
        }
    
    }

    &.team-style--bottom {
        ul {
            &.social-icon {
                top: auto;
                left: 40px;
                bottom: 105px;
            }
        }
        .content  {
            h4 {
                &.title {
                    margin-bottom: 1px;
                }
            }
        }
    }
  


    &:hover {



        

        .content {
            @media #{$large-mobile} {
                display: none;

            }
       
            h4 {
                
                &.title {
                    transition-delay: 0.25s;
                    opacity: 1;
                    visibility: visible;
                    transform: translateY(0);

                }
            }

            p{
          

            
                &.designation {
                    transition-delay: 0.33s;

                    opacity: 1;
                    visibility: visible;
                    transform: translateY(0);
                }
            }
        }
        .thumbnail {

            &::after{
                opacity: 0.85;
                top: 0;
                @media #{$large-mobile} {
                opacity: .01;

            }
            }
        }
        
        .content {

            opacity: 1;
            visibility: visible;
        }
    }
}










